import axios from '../axios'

export default class BaseClass {
    urlBase

    constructor(urlBase){
        this.urlBase = urlBase
    }
    
    async save(){
        if (this.id ?? this.rowid != null)
        {
            const res = await axios.put(`${this.urlBase}${this.id ?? this.rowid}`, this)
            return res
        }
        else {
            const res = await axios.post(this.urlBase, this)
            return res
        }
    }

    async batch(data){
        const res = await axios.post(`${this.urlBase}batch`, data)
        return res
    }

    async get(id){
        await axios.get(`${this.urlBase}${id}`).then(res => {
            console.log(res.data)
            for (const [key, value] of Object.entries(res.data)) {
                this[key] = value
            }
        })
    }

    async list(page = 1, page_size = 5, filtros = {}){
        let busqueda = filtros.busqueda ?? ''
        delete filtros.busqueda
        delete filtros.page
        delete filtros.step
        delete filtros.filter
        if(Object.values(filtros).every(x => x === null || x === '')){
            const res = await axios.get(`${this.urlBase}?page=${page}&size=${page_size}&search=${busqueda}`)
            return res
        }
        else {
            const res = await axios.post(`${this.urlBase}q/?page=${page}&size=${page_size}&search=${busqueda}`,filtros)
            return res
        }
    } 

    async put(id, data){
        const res = await axios.put(`${this.urlBase}${id}`, data)
        return res
    }

    async delete(){
        const res = await axios.delete(`${this.urlBase}${this.id}`)
        return res
    }

}
